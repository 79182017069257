import React,{useContext} from 'react';
import { GlobalDataContext } from '../context/context';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import BlockServices from './section-components/block-services';
import Footer from './global-components/footer';
// import Data from '../api/data.json'

const ServicesPage = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <Navbar />
        <PageHeader headertitle="Services" Subheader="Services"  backgroundImage={rpdata?.stock?.[7]}/>
        <BlockServices />
        <Footer />
    </div>
}

export default ServicesPage

